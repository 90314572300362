(function(app) {
    'use strict';

    //frt-activity-types
    app.directive('frtActivityTypes', function(_logicService, _i18n) {
        return {
            templateUrl: '/js/components/frtActivityTypes/activity-types.html',
            scope: {
                marker: '='
            },
            link: function (scope) {
                scope.sorted = _logicService.getSortedRegularActivities();
                scope.filteredActivitiesData = scope.marker.activities;

                scope.getLabelOf = function(key) {
                    return _i18n.getString('task.activity.' + key);
                };

            }
        };
    });


}) (angular.module('app'));